<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['จัดการสมาชิก', 'ตั้งค่าธนาคาร']" />
    <button
      @click="openModalCreate()"
      class="btn btn-success mb-2"
      v-show="canEdit"
    >
      เพิ่มบัญชีธนาคาร
    </button>

    <CDataTable
      :items="items"
      :fields="fields"
      :items-per-page-select="false"
      :items-per-page="20"
      :loading="tableLoading"
      :noItemsView="{
        noResults: 'ไม่พบข้อมูล',
        noItems: 'ไม่พบข้อมูล',
      }"
      :table-filter="true"
      hover
      pagination
      dark
      border
      addTableClasses="w-100 text-center"
    >
      <template #bankCode="{ item }">
        <td>
          <img
            :src="getImgUrl(item.bankCode)"
            width="20"
            :class="{
              'icon-scb': item.bankCode === 'SCB',
              'icon-kbank': item.bankCode === 'KBANK',
            }"
          />
        </td>
      </template>
      <template #edit="{ item }">
        <td>
          <button
            class="btn btn-primary"
            @click="openModalEdit(item)"
            :disabled="!canEdit"
          >
            <i class="fas fa-edit"></i>
          </button>
        </td>
      </template>
    </CDataTable>

    <b-modal
      id="modal-form"
      body-bg-variant="dark"
      body-text-variant="light"
      header-bg-variant="dark"
      header-text-variant="light"
      footer-bg-variant="dark"
      footer-text-variant="light"
      title="เพิ่มบัญชีธนาคาร"
    >
      <ValidationObserver ref="observer" tag="form" @submit.prevent="save()">
        <div class="form-group">
          <ValidationProvider rules="required" v-slot="v">
            <label for="accountName">ชื่อบัญชี</label>
            <input
              class="form-control"
              id="accountName"
              v-model="form.accountName"
            />
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <ValidationProvider rules="required|numeric" v-slot="v">
            <label for="accountNumber">เลขบัญชี</label>
            <input
              class="form-control"
              id="accountNumber"
              v-model="form.accountNumber"
            />
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <ValidationProvider rules="required|numeric" v-slot="v">
            <label for="promptpayNumber">เลขพร้อมเพย์</label>
            <input
              class="form-control"
              id="promptpayNumber"
              v-model="form.promptPayNumber"
            />
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <ValidationProvider rules="required|numeric" v-slot="v">
            <label for="phoneNumber">เบอร์โทรศัพท์</label>
            <input
              class="form-control"
              id="phoneNumber"
              v-model="form.phoneNumber"
            />
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <ValidationProvider rules="required|numeric" v-slot="v">
            <label for="pin">pin</label>
            <input class="form-control" id="pin" v-model="form.pin" />
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <ValidationProvider rules="required" v-slot="v">
            <label for="bankCode">ธนาคาร</label>
            <select class="form-control" id="bankCode" v-model="form.bankCode">
              <option value="" disabled>กรุณาเลือกธนาคาร</option>
              <option value="KBANK">KBANK ธ.กสิกรไทย</option>
              <option value="SCB">SCB ธ.ไทยพาณิชย์</option>
            </select>
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <ValidationProvider rules="required" v-slot="v">
            <label for="token">Token</label>
            <textarea
              class="form-control"
              id="token"
              v-model="form.token"
              rows="5"
            />
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <ValidationProvider rules="required" v-slot="v">
            <label for="status">สถานะ</label>
            <select class="form-control" id="status" v-model="form.status">
              <option value="ACTIVE">ใช้งาน</option>
              <option value="INACTIVE">ไม่ใช้งาน</option>
            </select>
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
      </ValidationObserver>
      <template #modal-footer>
        <b-button size="sm" variant="primary" @click="save()">บันทึก</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fields: [
        {
          key: "id",
          label: "#ID",
          _style: "width: 1%",
          sorter: false,
        },
        {
          key: "accountName",
          label: "ชื่อบัญชี",
          sorter: false,
        },
        {
          key: "accountNumber",
          label: "เลขบัญชี",
          sorter: false,
        },
        {
          key: "phoneNumber",
          label: "เบอร์โทรศัพท์",
          sorter: false,
        },
        {
          key: "bankCode",
          label: "ธนาคาร",
          filter: false,
          sorter: false,
        },
        {
          key: "updatedAtFormatted",
          label: "เวลา",
          filter: false,
          sorter: false,
        },
        {
          key: "status",
          label: "สถานะ",
          filter: false,
          sorter: false,
        },
        {
          key: "edit",
          label: "แก้ไข",
          filter: false,
          sorter: false,
        },
      ],
      items: [],
      tableLoading: true,
      form: {},
      isUpdate: false,
    }
  },
  methods: {
    getImgUrl(pic) {
      return require("@/assets/bank/" + pic + ".svg")
    },
    openModalEdit(item) {
      this.form = item

      this.$bvModal.show("modal-form")
    },
    openModalCreate() {
      this.form = {}

      this.$bvModal.show("modal-form")
    },
    save() {
      if (this.form.id) {
        this.updatePlatformBank()
      } else {
        this.createPlatformBank()
      }
    },
    async updatePlatformBank() {
      let observer = this.$refs.observer
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      this.swal.processing()

      try {
        await this.axios({
          method: "patch",
          url: "platform/bank",
          data: this.form,
        })

        await this.swal.success()

        this.$store.dispatch("auth/forceReload")
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
    async createPlatformBank() {
      let observer = this.$refs.observer
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      this.swal.processing()

      try {
        await this.axios({
          method: "post",
          url: "platform/bank",
          data: this.form,
        })

        await this.swal.success()

        this.$store.dispatch("auth/forceReload")
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
    async loadData() {
      this.tableLoading = true
      try {
        const { data } = await this.axios({
          method: "get",
          url: "platform/bank",
        })

        data.data?.forEach((item) => {
          item.phoneNumber = item.phoneNumber || ""
          item.updatedAtFormatted = this.$date(item.updatedAt).format(
            "HH:mm:ss DD/MM/YYYY"
          )
          item._classes = item.status == "INACTIVE" ? "table-danger" : ""
        })

        this.items = data.data

        this.tableLoading = false
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
  },
  mounted() {
    this.loadData()
  },
  computed: {
    canEdit() {
      return this.$store.state.auth.user?.userData?.permissions?.includes(
        "bank_setting_edit"
      )
    },
  },
}
</script>
